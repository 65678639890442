<template>
  <div class="apartment-order">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      orderData: {},
      opt: {
        title: "编辑账单",
        form: [
          {
            label: "订单编号",
            key: "orderNoBefore",
            disabled: true
          },
          {
            label: "交易编号",
            key: "transactionNoBefore",
            disabled: true
          },
          // {
          //   label: "到账时间",
          //   key: "dateEd",
          //   type: "date-time"
          // },
          {
            label: "对账时间",
            key: "reconDateBefore",
            disabled: true
          },
          {
            label: "订单金额(元)",
            key: "orderAmountBefore",
            disabled: true
          },
          {
            label: "到账金额",
            key: "payAmountBefore"
          },
          {
            key: "reconStateBefore",
            label: "对账状态",
            disabled: true
          },
          {
            label: "上传凭证",
            key: "correctFile",
            type: "upload-file",
            opt: {
              limit: 1,
              url: _this.UPLOAD_URL + '/user-service/weChat/uploadPic',
              tip: '上传图片文件到账凭证',
              upload(data) {
                debugger
                console.log(data);
                return true;
              },
              remove(file, fileList) {
                console.log(file, fileList);
                return true;
              }
            }
          },
          {
            label: "备注",
            key: "remark",
            type: "textarea"
          }
        ],
        buttons: [
          {
            name: "冲正",
            show: ["add", "edit"],
            cb(form) {
              let dto = {
                orderNo:  form.orderNoBefore,
                orderAmount:  form.orderAmountBefore,
                payAmountBefore: _this.payAmountBefore,  //冲正前的金额
                payAmountAfter: form.payAmountBefore, //冲正后的金额
                correctFile: form.correctFile[0],
                remark: "财务冲正",
              };
              let url = "finance-service/financeCorrect/correctOrder";
              let message = "冲正成功";
              _this.post(url, dto, {
                isMultipart: true
              }).then(function() {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          }
        ],
        get(opt) {
          let dto = {
            orderNo: opt.id
          };
          _this.post("/finance-service/reconStatisticsDay/searchReconLeaseOrderCorrectDetail", dto).then(data => {
            data.dateIngStr = _this.format(data.dateIng);
            data.reconStateBefore = ["已对账成功", "对账异常", "已冲正"][data.reconStateBefore - 1];
            _this.payAmountBefore = data.payAmountBefore; //记录冲正前金额
            delete data.correctFile; //删除凭证字段
            debugger
            opt.cb(data);
          });
        }
      }
    };
  },
  created: function() {
    console.log("apartment-order created!!");
    this.orderData = this.$route.query;
  }
};
</script>
<style lang="scss">
.apartment-order {
  .el-input.is-disabled .el-input__inner {
    color: #c0c4cc;
  }
}
</style>
